.navbar {
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 100;
    background: linear-gradient(to bottom, rgba(192, 32, 45, 0.9), rgba(192, 32, 45, 0.8) );
    width: 100%;
    margin: 0;
    padding: 0;
    box-shadow: 0px 0px 30px 2px grey;
}

.navbar-right {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
}

.top-menu {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 10px;
}

.navbar-right-apply{
    display: flex;
    flex-direction: column;
    width: 14%;
}

.apply-button a {
    display: block;
    background: #392F2D;
    width: 100%;
    padding: 0.5em 2em;
    color: white;
    text-align: center;
    font-size: 18.8px;
    font-weight: 500;
    cursor: pointer;
}

.apply-button a:hover {
    background: #5E5553;
}

.navbar_brand {
    display: flex;
    flex-direction: row;
    background: white;
    padding: 5px 15px 5px 7px;
    margin-left: 20px;
    border-radius: 24px;
}

.navbar_brand:hover {
    text-decoration: none;
    color: #FF3343;
}

.logo {
    width: 36px;
    height: 36px;
}

.logo-text {
    font-size: 11px;
    text-align: center;
    padding-left: 2px;
    color: #FF0043;
}

.menu-bar {
    margin-right: 10px;
    height: 100%;
}

.menu-bar > ul {
    display: flex;
    flex-flow: row;
    margin: 0;
    padding: 0;
}

.menu-bar > ul > li {
    list-style: none;
    text-align: center;
    min-width: 150px;
    font-family: dosis;
    color: #FF003A;
}

.menu-bar > ul > li > a {
    background: white;
    display: block;
    padding: 10px 10px 10px 10px;
    border-radius: 25px;
    cursor: pointer;
    margin: 0px 3px;
    color: #FF003A;
}

.menu-bar > ul > li > a:hover {
    text-decoration: none;
    color: #5E5553;
}

.menu-bar > ul > li:hover {
    color: #5E5553;
}
.nav-hum-md,.nav-hum-sm{
    display: none;
}

@media screen and (max-width: 1000px){
    .navbar-right{
        display: none;
    }
    .navbar,.apply-button a{
        font-size: 17px;
    }
    .navbar-right-apply{
        width: 200px;
    }
    .nav-hum-md{
        display: block;
        margin-right: 1%;
    }
    .humburger{
        height: 50px;
        width: 50px;
        border-radius: 10%;
        border: 1px solid white;
        background: linear-gradient(to bottom, rgba(192, 32, 45, 0.9), rgba(192, 32, 45, 0.8) );
    }
    .hum-line{
        margin-top: 21%;
        margin-left: 23%;
        height: 3px;
        width: 50%;
        background: white;
    }
    .humburger:hover{
        cursor: pointer;
    }
    .navbar-right-md{
        margin-right: 200px;
        width: 200px;
        right: 0px;
        position: absolute;
        margin-top: 8px;
        display: none;
    }
    .menu-bar-md > ul{
        background: linear-gradient(to bottom, rgba(192, 32, 45, 0.9), rgba(192, 32, 45, 0.8) );
        padding: 0;
        list-style: none;
    }
    .menu-bar-md> ul> li> a{
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
        padding-left: 30px;
        display: block;
        color: white;
        font-size: 18.8px;
    }
    .menu-bar-md > ul > li> a:hover{
        color: rgb(223, 31, 31);
        background-color: rgb(228, 207, 207);
        text-decoration: none;
    }
}
@media screen and (max-width:576px){
    .humburger,.navbar-right-md,.navbar-right-apply,.menu-bar-md{
        display: none;
    }
    .nav-hum-sm{
        display: block;
        margin-right: 2%;
    }
    .humburger-sm{
        height: 50px;
        width: 50px;
        border-radius: 10%;
        border: 1px solid white;
        background: linear-gradient(to bottom, rgba(192, 32, 45, 0.9), rgba(192, 32, 45, 0.8) );
    }
    .hum-line-sm{
        margin-top: 21%;
        margin-left: 23%;
        height: 3px;
        width: 50%;
        background: white;
    }
    .humburger-sm:hover{
        cursor: pointer;
    }
    .navbar-right-sm{
        left: 0;
        width: 100%;
        position: absolute;
        margin-top: 8px;
        display: none;
    }
    .menu-bar-sm > ul{
        background: linear-gradient(to bottom, rgba(192, 32, 45, 0.9), rgba(192, 32, 45, 0.8) );
        padding: 0;
    }
    .menu-bar-sm > ul > li> a{
        font-size: 18.8px;
        color: white;
        cursor: pointer;
        display: block;
        padding-left: 40px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .menu-bar-sm > ul >li:last-child> a{
        font-size: 22px;
        font-weight: 500;
    }
    .menu-bar-sm > ul >li> a:hover{
        background-color: rgb(228, 207, 207);
        color: rgb(223, 31, 31);
        text-decoration: none;
    }
    .menu-bar-sm > ul >li:last-child>a{
        background: #392F2D;
    }
    .menu-bar-sm > ul >li:last-child>a:hover{
        background: #5E5553;
        text-decoration: underline;
        color: white;
    } 
}


/* After user login */

.login-navbar-right{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
}
.new-job{
    display: flex;
    flex-direction: row;
    background: white;
    padding: 3.5px 15px 2px 3px;
    border-radius: 24px;
    margin-top: 5px;
    margin-bottom: 5px; 
    margin-right: 25px;
}
.new-job:hover{
    text-decoration: none;
}
.new-job:hover .new-job-text{
    color: #5E5553;
}
.add-pic, .user-pic{
    width: 30px;
    height: 30px;
}

.new-job-text{
    padding-top: 2px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    color: #FF0043;
    font-weight: 600;
}
.user{
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: row;
    background: #ED264A;
    padding: 3.5px 15px 3.5px 4px;
    border-radius: 24px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 0 0px 10px rgba(0,0,0,0.15);
}
.username-login{
    padding-top: 2px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    font-weight: 600;
}
.user-login{
    margin-right: 10px;
}
.user:hover{
    cursor: pointer;
}
.dropdown{
    padding-top: 10px;
    height: 20px;
    width: 10px;
}
.user-menu{
    z-index: 3;
    height: 100px;
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #ED264A;
    border-radius: 10px;
    margin-top: -25px;
}
.profile{
    padding-top: 30px;
    padding-bottom: 5px;
}
.line-under-profile{
    border-bottom: 1px solid white;
    opacity: 0.8;
    margin-left: 15px;
}
.logout{
    padding-top: 3px;
}
.profile, .logout{
    padding-left: 25px;
    color: white;
    font-weight: 530;
}
.profile:hover, .logout:hover{
    text-decoration: none;
    color: #5E5553;
}