/*@font-face {
  font-family: 'dosis';
  src: url('https://fonts.googleapis.com/css?family=Dosis');
}*/

@import url("https://fonts.googleapis.com/css?family=Battambang&display=swap");
@import url("https://fonts.googleapis.com/css?family=Dosis:700,500,300");

body {
  margin: 0;
  padding: 0;
  font-family: dosis, Lato, 'sans-serif', battambang;
}

