@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

* {
  outline: none;
}
.slider-wrapper {
  position: relative;
  margin: 0em auto;
  height: 100vh;
  overflow: hidden;
  z-index: -2;
}

.slide {
  height: 100vh;
  background-size: cover !important
}

.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    /* background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9)); */
    bottom: 0;
    left: 0;
}

.previousButton, .nextButton {
  position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
  background: url('./images/arrow-point-to-right.svg') no-repeat center center / 28px;
	width: 36px;
	height: 36px;
	text-indent: -9999px;
  cursor: pointer;
}

.previousButton:hover, .nextButton:hover {
  background: url('./images/arrow-point-to-right.svg') no-repeat center center / 28px;
}

.previousButton {
  margin-left: 6px;
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
          transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
    left: -10px;
}

.nextButton {
    right: 0;
    margin-right: 6px;
}

.nextButton:hover {
  right: -10px;
}

.slider-content .inner {
  padding: 120px 100px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 28%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.slider-content .inner h1 {
  font-weight: 900;
  color: #FFFFFF;
  font-size: 2.8em;
  line-height: 1;
  text-shadow: 0px 0px 20px #000000;
}

.slider-content .inner p {
  color: #FFFFFF;
  font-size: 1.25em;
  line-height: 1;
  margin-left: 6px;
  margin-top: -22px;
  font-weight: 100;
  text-shadow: 2px 0px 20px #000000;
  width: 35%;
  line-height: 30px;
}
@media (min-width:450px) and (max-width: 890px){
  .slider-content .inner{
    padding: 0px 5px;
    margin-top: -8px;
  }
  .slider-content .inner h1 {
    font-weight: 900;
    color: #FFFFFF;
    font-size: 1.875em;
    width: 100%;
    line-height: 1;
    margin-left: 5%;
    text-shadow: 0px 0px 20px #000000;
  }
  
  .slider-content .inner p {
    color: #FFFFFF;
    font-size: 1.25em;
    line-height: 1.5;
    margin-left: 5%;
    margin-top: -20px;
    font-weight: 100;
    text-shadow: 2px 0px 20px #000000;
    width: 90%;
  }
}

@media (max-width: 450px){
  .slider-content .inner{
    padding: 0px 5px;
    margin-top: -8px;
  }
  .slider-content .inner h1 {
    font-weight: 900;
    color: #FFFFFF;
    font-size: 1.875em;
    line-height: 1;
    text-shadow: 0px 0px 20px #000000;
    width: 100%;
    margin-left: 6px;
  }
  
  .slider-content .inner p {
    color: #FFFFFF;
    font-size: 15px;
    line-height: 1.5;
    margin-left: 6px;
    margin-top: -20px;
    font-weight: 100;
    text-shadow: 2px 0px 20px #000000;
    width: 90%;
  }
}