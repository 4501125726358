.btnpill{
    border-radius:30px;
}
/* .textbox{
    border-radius: 100px 30px 30px 100px;
} */
.form-group{
    height: 65px;
    margin-bottom: 6px;
}
.form-control{
    padding-left: 2em;
}
.textbox-left{
    border-radius: 100px 30px 30px 100px;
}

.textbox-right{
    border-radius: 30px 100px 100px 30px;
}
.textbox-pill{
    border-radius: 100px 100px;
}