.our-service {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto; 
    text-align: center;
}

.title h1 {
    color: rgba(192, 32, 45, 0.904);
    font-weight: 600;
    padding: 26px 0;
    font-size: 46px;
}

.description p {
    word-spacing: 3px;
    font-size: 20px;
}

.our-process {
    margin: 30px auto;
    max-width: 78%;
    border: 2px solid rgb(224, 224, 224);
    border-radius: 2px;
}

.our-process .process-wrapper {
    margin: 0 auto;
    max-width: 92%;
    padding: 20px 0;
}

.our-process .block {
    float: left;
    padding: 8px 24px;
    background-color: rgb(4, 122, 34);
    color: #fff;
    border-radius: 10px;
    margin: 4px;
    font-size: 20px;
    font-weight: 500;
}