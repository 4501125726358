.description {
    width: 80%;
    margin: 0 auto;
}

.mission-vision-holder {
    display: flex;
    flex-direction: row;
    margin: 2.5em 0;
}

.our-vision, .our-mission {
    width: 50%;
    text-align: center;
    margin: 2em 2.5em;
}

.our-mission p, .our-vision p {
    margin-top: 1em;
}

.our-vision-head {
    background: url("./images/our-vision.png") no-repeat;
    background-size: 2em;
    padding: 0em 2em 0em 2.5em;

    color: rgba(192, 32, 45, 0.904);
    font-weight: 700;
    font-size: 26px;
    
}

.our-mission-head {
    background: url("./images/our-mission.png") no-repeat;
    background-size: 1.7em;
    padding: 0.5em 2em 2em 2.2em;
    vertical-align: middle;
    
    color: rgba(192, 32, 45, 0.904);
    font-weight: 700;
    font-size: 26px;
    margin: 0 auto;
}
@media only screen and (max-width: 800px){
    .mission-vision-holder{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .our-vision, .our-mission {
        width: 100%;
    }
}