.announcement-list {
    width: 85%;
    margin-top: -21em;
    margin-left: auto;
    margin-right: auto;
    background-color: light;
    border-radius: 50px;
    padding: 50px 50px;
    position: relative;
    background-color: white;
    min-height: 25em;
}
.announcement-list:after{
    border-radius: 50px;
    position: absolute;
    content: '';
    width: 100%;
    height: 22.5em;
    top: 0;
    left:0;
    z-index: -1;
    box-shadow: 0px 0px 10px rgba(0,0,0,1);
}
.announcement-list table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
}

.announcement-list thead th{
    text-align: center;
    background: rgba(192, 32, 45, 0.904);
    color: white;
    padding: 0.5em;
}

.announcement-list tbody tr td {
    padding: 1em;
}

.announcement-list tr:nth-child(even) {
    background: rgba(192, 32, 45, 0.15);
}

.announcement-list tr:nth-child(odd) {
    background: rgba(192, 32, 45, 0.02);
}

.announcement-list tr:hover {
    cursor: pointer;
}
.job-industry, .job-location{
    width: 20%;
}

@media (min-width:450px) and (max-width: 890px){
    .announcement-list {
        padding: 1em;
        width: 100%;
        border-radius: 0%;
    }

    .announcement-list tbody tr td {
        padding: 1em;
    }
}

@media (max-width: 450px){
    .announcement-list {
        border-radius: 0%;
        width: 100%;
        padding: 1em;
    }

    .announcement-list tbody tr td {
        padding: 0.75em;
    }

    .announcement-list tr th {
        padding: 0.75em;
    }

    .job-industry {
        display: none;
    }
}