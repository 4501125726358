.upload-inner {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-inner > .draggable {
    width: 100%;
    height: 150px;
    background-color: rgba(147, 148, 148, 0.1);
    padding: 6px;
    border-radius: 4px;
    border: 2px dashed rgba(95, 92, 92, 0.2);
    font-size: 14px;
    display: flex;
    margin-bottom: 7px;
    position: relative;
    overflow: hidden;
}

.upload-inner > .draggable > #file-browser-input {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    color: transparent;
    opacity: 0;
}
.upload-inner > .files-preview {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: 99;
    overflow-x: auto;
    overflow-y: hidden;
    flex-shrink: 0;
    padding: 5px;
}

.upload-inner > .files-preview > .file {
    width: 5.5em;
    height: 100px;
    position: relative;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-left: 39%;
}

.upload-inner > .files-preview > .file > img {
    width: 100%;
    height: 100%;
}

.upload-inner > .files-preview > .file > .file-type {
    color: #ED264A;
    width: 100%;
    height: 100%;
}
.upload-inner > .files-preview > .file > .remove-button {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    transition: background, 160ms ease-in-out;
}

.upload-inner > .files-preview > .file > .remove-button:hover .remove-btn {
    visibility: visible;
}

.upload-inner > .files-preview > .file > .remove-button > .remove-btn {
    color: #ca4240;
    width: fit-content;
    position: absolute;
    right: -7px;
    top: -7px;
    z-index: 99;
    cursor: pointer;
    visibility: hidden;
}

.upload-inner > .files-preview > .file-detail {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-inner > .draggable > .cloud-icon{
    font-size: 100px;
    margin-left: 35%;
    opacity: 0.6;
    color: #ED264A;
    pointer-events: none;
}
.upload-inner > .draggable > .file-browser {
    position: absolute;
    display: flex;
    justify-self: center;
    align-self: center;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
}

.upload-inner > .draggable > .file-browser > #anchor:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: lightblue;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
}