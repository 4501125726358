.post-content {
    padding: 10vw 10vw;
}

@media (min-width:1500px){
    .post-content {
        padding: 7vw 20vw;
    }
}

@media (max-width:1000px){
    .post-content {
        padding: 20vw 10vw;
    }
}
.article-content{
    line-height: 2.0;
}