.container {
    width: 88%;
    margin: 0 auto;
    padding-top: 160px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
}

.login-signup{
    width: 100%;
}

.login-part,.signup-part{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 100px;
}

/************
LOG IN PART
*************/
.login-part{
    float: left;
    width: 100%;
    position: relative;
    z-index: 1;
}

.login-part > .login-container {
    width: 44%;
    box-shadow: 0 0 12px 1px rgb(235, 128, 128);
    height: 560px;
}

.login-part > .login-container .header {
    background-color: rgb(173, 66, 66);
    padding: 10px;
    border: 1px rgb(173, 66, 66) solid;
}

.login-part > .login-container .header .header-des {
    margin: 0 auto;
    color: #fff;
    max-width: 88%;
    background-image: url('./images/key.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 56px 56px;
}

.login-part > .login-container .body {
    background-color: rgb(235, 235, 235);
    border: 1px rgb(235, 235, 235) solid;
    height: 478px;
}

.login-part > .login-container .body .body-des {
    margin: 0 auto;
    max-width: 88%;
    padding-top: 40px;
}

.login-part > .login-container .body .body-des input[type="text"],
.login-part > .login-container .body .body-des input[type="password"] {
    width: 100%;
    height: 40px;
    margin-bottom: 26px;
    background-color: #fff;
    padding-left: 56px;
    border: 0px;
    border-radius: 100px 30px 30px 100px;
}

.login-part > .login-container .body .body-des .form-input-username::before {
    background-image: url('./images/user.png');
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    margin-left: 17px;
    margin-top: 11px;
    width: 15px;
    height: 18px;
    background-size: 15px 18px;
    border-right: 2px solid lightgrey;
    padding-left: 25px;
}

.login-part > .login-container .body .body-des .form-input-password::before {
    background-image: url('./images/login-key.png');
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    margin-left: 17px;
    margin-top: 11px;
    width: 15px;
    height: 18px;
    background-size: 15px 18px;
    border-right: 2px solid lightgrey;
    padding-left: 25px;
}
.login-part > .login-container .body .form-input-submit{
    margin-top: 20px;
}
.login-part > .login-container .body .loginSubmit {
    background-color: rgb(173, 66, 66);
    color: #fff;
    border: 2px #fff solid;
    border-width: 2px 0 2px 2px;
    border-radius: 100px 0 0 100px;
    padding: 8px 60px;
    font-weight: 600;
}
.login-part > .login-container .body input::-webkit-input-placeholder{
    color: lightgrey;
}
.login-part > .login-container .body .form-input-submit {
    text-align: right;
}
.login-part > .login-container .body .form-input-submit input[type = "submit"]{
    cursor: pointer;
}
.login-part > .qa-container {
    width: 50%;
    margin-left: 6%;
}
.login-part > .qa-container .itjob-logo {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    box-shadow: 0 0 10px 0.5px #888;
    margin-top: -30px;
    margin-left: 60px;
}
.login-part > .qa-container h5 {
    color: rgb(73, 85, 121);
    font-weight: 600;
}

.login-part > .qa-container .qa {
    margin: 0 auto;
    max-width: 80%;
    text-align: center;
}
.login-part > .qa-container .signup-btn {
    background-color: rgb(173, 66, 66);
    border-radius: 10px;
    width: 220px;
    padding: 8px 0;
    margin: auto;
    color: #fff;
    cursor: pointer;
}

/************
SIGN UP PART
*************/

.signup-part{
    position: absolute;
    margin-right: 6%;
}

.signup-part > .login-container-2 {
    width: 44%;
    box-shadow: 0 0 12px 1px rgb(30, 90, 139);
    flex-direction: row;
}

.signup-part > .login-container-2 .header-2 {
    background-color: rgb(30, 90, 139);
    padding: 10px;
    border: 1px rgb(30, 90, 139) solid;
}

.signup-part > .login-container-2 .header-2 .header-des {
    margin: 0 auto;
    color: #fff;
    max-width: 88%;
    background-image: url('./images/key.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 56px 56px;
}

.signup-part > .login-container-2 .body-2 {
    background-color: rgb(235, 235, 235);
    border: 1px rgb(235, 235, 235) solid;
    height: 478px;
}

.signup-part > .login-container-2 .body-2 .body-des {
    margin: 0 auto;
    max-width: 88%;
    padding-top: 40px;
}

.signup-part > .login-container-2 .body-2 .body-des input[type="text"],
.signup-part > .login-container-2 .body-2 .body-des input[type="password"]{
    width: 100%;
    height: 40px;
    margin-bottom: 26px;
    background-color: #fff;
    padding-left: 34px;
    border: 0px;
}

.signup-part > .login-container-2 .body-2 .body-des input::-webkit-input-placeholder{
    color: lightgrey;
}
.signup-part > .login-container-2 .body-2 .body-des .form-input-username{
    display: flex;
    flex-direction: row;
}

.signup-part > .login-container-2 .body-2 .body-des .form-input-username .form-fn{
    border-radius: 100px 30px 30px 100px;
    margin-right: 10px;
}
.signup-part > .login-container-2 .body-2 .body-des .form-input-username .form-ln {
    border-radius: 30px 100px 100px 30px;
    margin-left: 10px;
}

.signup-part > .login-container-2 .body-2 .body-des .others-form {
    border-radius: 100px;
}

.signup-part > .login-container-2 > .body-2 .form-input-submit{
    margin-top: 20px;
}

.signup-part > .login-container-2 > .body-2 .submit {
    background-color: rgb(30, 90, 139);
    color: #fff;
    border: 2px #fff solid;
    border-width: 2px 0 2px 2px;
    border-radius: 100px 0 0 100px;
    padding: 8px 60px;
    font-weight: 600;
}

.signup-part > .login-container-2 > .body-2 .form-input-submit {
    text-align: right;
}

.signup-part > .login-container-2 > .body-2 .form-input-submit input[type = "submit"]{
    cursor: pointer;
}

.signup-part > .qa-container-2 {
    width: 50%;
    margin-right: 6%;
}

.signup-part > .qa-container-2 .itjob-logo {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    box-shadow: 0 0 10px 0.5px #888;
    margin-top: -30px;
    margin-left: 60px;
}

.signup-part > .qa-container-2 h5 {
    color: rgb(73, 85, 121);
    font-weight: 600;
}

.signup-part > .qa-container-2 .qa {
    margin: 0 auto;
    max-width: 80%;
    text-align: center;
}

.signup-part > .qa-container-2 .login-btn {
    background-color: rgb(173, 66, 66);
    border-radius: 10px;
    width: 220px;
    padding: 8px 0;
    margin: auto;
    color: #fff;
    cursor: pointer;
}
