.member-container {
    background-color: rgb(233, 219, 227);
}
.member-wrapper {
    max-width: 80%;
    margin: 0 auto;
    padding-bottom: 40px;
}

.title h1 {
    color: rgba(192, 32, 45, 0.904);
    font-weight: 600;
    padding: 26px 0;
    font-size: 46px;
    text-align: center;
}

.description p {
    word-spacing: 3px;
    font-size: 20px;
    text-align: center;
}

.box { 
    border: 1px solid rgb(185, 185, 185);
    border-radius: 2px;
    margin: 30px auto auto auto;
    max-width: 90%;
    padding: 3%;
    display:flex;
    justify-content: center;
    position: relative;
}

.box .image {
    width: 30%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.box .image img {
    border: 1px solid transparent;
    border-radius: 50%;
}

.box .info {
    width: 50%;
}

.box .social {
    width: 10%;
    display: flex;
    justify-content: center;
    padding-right: 11%;
    margin-bottom: 0.5%;
}
.box .info .sub--info span {
    display: block;
}

.sub--info span:nth-child(1) {
    color: rgba(192, 32, 45, 0.904);
    font-size: 24px;
    font-weight: 700;
}

.sub--info span:nth-child(2) {
    font-size: 18px;
    font-weight: 600;
}

.sub--info span:nth-child(6), .sub--info span:nth-child(7), .sub--info span:nth-child(8) {
    text-indent: 30px;
}

.box .social .sub--social {
    align-self: flex-end;
}

.box .social .sub--social .logo {
    width: 46px;
    height: 42px;
    margin-left: 40px;
}

@media only screen and (max-width: 800px){
    .sub--info span:nth-child(1), .sub--info span:nth-child(2){
        text-align: center;
    }
    .box .info{
        width: 75%;
    }
    .social{
        margin-top: 1%;
    }
    .box{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}