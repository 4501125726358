.userjoblist_container{
    width: 85%;
    padding-top: 10%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    padding-bottom: 7%;
}
.button{
    width: 100%;
    margin-bottom: 3%;
}
.button > .unpublish,
.button > .delete{
    background-color: #ED264A;
    color: white;
    border: 0;
    padding: 6px 15px;
    border-radius: 12px;
}
.button > .unpublish{
    margin-right: 3%;
}
.button > .unpublish:hover,
.button > .delete:hover{
    cursor: pointer;
}
.job-row:hover {
    box-shadow: 0px 3px 8px -2px rgba(0,0,0,0.5);
}
.square{
    color: grey;
    margin-right: 14px;
    font-size: 14px;
}
.pencil{
    color: grey;
    margin-right: 14px;
    font-size: 14px;
}
.square:hover,
.pencil:hover{
    color: black;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 10px rgba(0,0,0,0.1);
    background: rgba(0,0,0,0.1);
}
.user-job-list{
    width: 100%;
}
.user-job-list table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
}

.user-job-list thead th{
    text-align: center;
    background: rgba(192, 32, 45, 0.904);
    color: white;
    padding: 0.5em;
}

.user-job-list tbody tr td {
    padding: 1em;
}

.user-job-list tr:nth-child(even) {
    background: rgba(192, 32, 45, 0.15);
}

.user-job-list tr:nth-child(odd) {
    background: rgba(192, 32, 45, 0.02);
}

.user-job-list tr:hover {
    cursor: pointer;
}
.user-job-industry, .user-job-location{
    width: 20%;
}