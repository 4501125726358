.footer {
    background: rgba(192, 32, 45, 0.904);
    padding: 3em 5em;
    color: white;
    font-size: 25px;
    font-weight: 200;
}

.footer-menu {
    margin: 0.5em auto;
    font-weight: 600;
}

.footer-menu ul, .footer-social-logo ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.footer-menu > ul > .list-left, .footer-menu > ul > .list-right{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.footer-menu ul li {
    list-style: none;
    padding: 0 2em;
    width: 240px;
    text-align: center;
}

.footer-menu ul li a {
    text-decoration: none;
    color: white;
}

.footer-menu ul li a:hover {
    color: #dddddd;
    cursor: pointer;
}

.footer-social-logo {
    margin: 0 auto;
}

.footer-social-logo ul li {
    list-style: none;
    padding: 1em;
}

.footer-address {
    margin: 0.6em 0 2em 0;
    text-align: center;
}

.footer-copyright {
    border-top: 2px solid white;
}

.footer-copyright-inner {
    text-align: center;
    padding-top: 1em;
}

@media (min-width:450px) and (max-width: 885px){

    .footer {
        padding: 3em 1em;
    }

    .footer-menu > ul{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .footer-menu > ul > .list-left,.footer-menu > ul > .list-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media (max-width: 450px){
    .footer {
        padding: 3em 1em;
    }

    .footer-menu > ul{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .footer-menu > ul > .list-left,.footer-menu > ul > .list-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}