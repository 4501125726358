.posting_container {
    width: 80%;
    margin: 0 auto;
    padding-top: 10%;
}

.job{
    width: 100%;
    padding: 20px;
    margin-bottom: 50px;
    box-shadow: 0 0px 100px rgba(0,0,0,0.15);
}

.job-announce{
    width: 100%;
    color: #FF0043;
    text-align: center;
    font-size: 20px;
    margin-bottom: 40px;
}

.form-announce{
    width: 100%;
}
.form-announce > .job-title,
.form-announce > .salary,
.form-announce > .job-des,
.form-announce > .job-require,
.form-announce > .job-industry,
.form-announce > .job-location{
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.form-announce > .job-title > .job-title-text,
.form-announce > .salary > .salary-text,
.form-announce > .job-des > .des-text,
.form-announce > .job-require > .require-text,
.form-announce > .job-industry > .job-industry-text,
.form-announce > .job-location > .job-location-text{
    text-align: right;
    margin-right: 10px;
    width: 20%;
    font-size: 20px;
    padding-top: 3px;
}
.form-announce > .job-des > .des-text,
.form-announce > .job-require > .require-text{
    padding-top: 16px;
}
.job-title > .job-title-input,
.job-industry > .job-industry-input,
.job-location > .job-location-input{
    width: 40%;
    height: 40px;
    border:solid 1px #ccc;
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
}
.form-announce > .salary >.salary-to,
.form-announce > .salary > .salary-current{
    padding-top: 5px;
    font-size: 20px;
    margin-left: 5px;
    margin-right: 5px;
}
.form-announce > .salary > .salary-input{
    height: 40px;
    border-radius: 15px;
    border:  solid 1px #ccc;
    width: 15%;
    padding-left: 10px;
    padding-right: 10px;
}
.form-announce > .job-des > .des-edit,
.form-announce > .job-require > .require-edit{
    width: 72%;
    border: 1px solid #ccc;
    padding: 20px;
}
.form-announce > .recaptcha{
    margin-top: 25px;
    margin-left: 21%;
    margin-bottom: 5%;
}

.form-announce > .button{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 6.5%;
    margin-bottom: 5%;
}
.form-announce > .button > .create{
    background-color: #ED264A;
    border: none;
    color: white;
    text-align: center;
    width: 22%;
    font-size: 20px;
    margin-left: 10px;
    padding: 7px 10px;
    font-weight: 600;
    border-radius: 16px;
}
.form-announce > .button > .create:hover{
    cursor: pointer;
}
.form-announce > .button > .cancel{
    border-radius: 16px;
    background-color: white;
    border: 0.5px solid #ED264A;
    color: #ED264A;
    text-align: center;
    font-size: 20px;
    width: 22%;
    font-weight: 600;
    padding: 7px 10px;
}
.form-announce > .button > .cancel:hover{
    background-color: #ED264A;
    color: white;
    cursor: pointer;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v42/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
  
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
blockquote{
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}

@media only screen and (max-width: 800px){

    .posting_container {
        width: 95%;
        margin: 0 auto;
    }
    
    .job {
        width: 100%;
        padding: 20px;
        margin-bottom: 50px;
        box-shadow: 0 0px 100px rgba(0,0,0,0.15);
    }

    .job-announce{
        font-size: 1.3em;
    }

}