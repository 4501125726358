.contact-us {
    background: #006DBE;
}

.contact-us .holder {
    max-width: 75%;
    margin: 0 auto;
}

.title-white h1 {
    color: white;
    font-weight: 600;
    padding: 26px 0;
    font-size: 46px;
    text-align: center;
    margin: 0 auto;
}

.description-white p {
    word-spacing: 3px;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    color: white;
    padding-bottom: 2em;
    margin: 0;
}