.blogpost-container {
    padding: 10vw 10vw;
}

@media (min-width:1500px){
    .blogpost-container{
        padding: 7vw 20vw;
    }
}

@media (max-width:1000px){
    .blogpost-container {
        padding: 20vw 10vw;
    }
}