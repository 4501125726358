.announcement_container {
    width: 88%;
    margin: 0 auto;
    padding-top: 160px;
    padding-bottom: 30px;
}

.announcement {
    padding: 1em 4em;
}

.salary-range {
    color: #ff3d7b;
    font-weight: bold;
    font-size: 1.3em;
}
.button-apply-job {
    margin-top: 5%;
}
.button-apply-job > .apply-job {
    background-color: #ED264A;
    border: none;
    color: white;
    text-align: center;
    width: 150px;
    font-size: 20px;
    padding: 7px 10px;
    font-weight: 600;
    border-radius: 16px;
}

.popup-title {
    font-size: 24px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    background-color: #ED264A;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 4px;
}

input::-webkit-input-placeholder{
    color: lightgrey;
}

.popup-inner > .popup-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 30px 0px 30px;
}

.popup-inner > .popup-form > input[type="text"] {
    border-radius: 15px;
    border:  solid 1px #ccc;
    padding-left: 15px;
    padding-right: 15px;
    height: 30px;
}

.popup-inner > .popup-form > .row > .col > .textbox-left {
    border-radius: 100px 30px 30px 100px;
    border:  solid 1px #ccc;
    padding-left: 15px;
    padding-right: 20px;
    width: 100%;
    height: 30px;
}

.popup-inner > .popup-form > .row > .col > .textbox-right {
    border-radius: 30px 100px 100px 30px;
    border:  solid 1px #ccc;
    padding-left: 15px;
    padding-right: 20px;
    width: 100%;
    height: 30px;
}

.popup-inner > .popup-form > .upload-cv-file {
    margin-left: 10px;
    margin-top: -15px;
    margin-bottom: 15px;
}

.popup-inner > .popup-form > .recaptcha {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.popup-inner > .popup-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 15px;
}

.popup-inner > .popup-button > .popup-submit {
    background-color: #ED264A;
    border: none;
    color: white;
    text-align: center;
    width: 22%;
    margin-left: 10px;
    padding: 3px 6px;
    font-weight: 600;
    border-radius: 16px;
}

.popup-inner > .popup-button > .popup-close {
    border-radius: 16px;
    background-color: white;
    border: 0.5px solid #ED264A;
    color: #ED264A;
    text-align: center;
    width: 22%;
    font-weight: 600;
    padding: 3px 6px;
}

.popup-inner > .popup-button > .popup-close:hover {
    background-color: #ED264A;
    color: white;
    cursor: pointer;
}

.button-apply-job > .apply-job:hover,
.popup-inner > .popup-button > .popup-submit:hover {
    cursor: pointer;
}

@media only screen and (max-width: 800px){

    .announcement_container {
        width: 95%;
        margin: 0 auto;
        padding: 6em 0.5em 2em 0.5em;
    }

    .announcement {
        padding: 0;
    }

}

#overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 10003;
    cursor: pointer;
  }
  
  #text{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }
